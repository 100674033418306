<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">报名管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">报名资料下载</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em;">招生任务名称:</span>
              <el-input v-model="projectName" type="text" size="small" placeholder="请输入招生任务名称" clearable />
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed width="50px" />
              <el-table-column label="招生任务名称" prop="projectName" show-overflow-tooltip min-width="280" />
              <el-table-column label="类型" show-overflow-tooltip prop="categoryName" min-width="120" />
              <el-table-column label="招生有效时间" show-overflow-tooltip min-width="160">
                <template slot-scope="scope">{{ scope.row.startDate + '至' + scope.row.endDate }}</template>
              </el-table-column>
              <el-table-column label="已招生人数" min-width="140" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.projectApplyPeople || '0' }}</template>
              </el-table-column>
              <el-table-column label="创建时间" min-width="140">
                <template slot-scope="scope">{{ scope.row.createTime || '--' }}</template>
              </el-table-column>
              <el-table-column label="状态" align="left" show-overflow-tooltip prop="releaseState" min-width="80">
                <template slot-scope="scope">{{ scope.row.projectState || '--' }}</template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" align="center" width="160px">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="showProject(scope.row)">下载材料</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="downloadApplicationForm(scope.row)" :disabled="!scope.row.showApplyFormDownloadButton">下载申请表</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <DownloadFile ref="DownloadFile" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// downloadFile
// import DownloadFile from "@/views/institutionalManagement/popup/downloadFile.vue";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "downloadInfo",
  components: {
    Empty,
    PageNum
    // DownloadFile
  },
  mixins: [List],
  data() {
    return {
      projectName: "",
    };
  },
  created() {
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      this.doFetch({
        url: "/biz/ct/downLoad/ctDownLoadProjectList",
        params,
        pageNum
      });
    },
    // 下载材料
    showProject(row) {
      this.$router.push({
        path: "/web/institutionalManagement/downloadFile",
        query: {
          projectName: row.projectName,
          occupationId: row.occupationId,
          projectId: row.projectId,
          startDate: row.startDate,
          endDate: row.endDate
        },
        // params: {
        //   refresh: true,
        //   isAdd: false,
        // },
      });
      // this.$refs.DownloadFile.showPopUp(row); // 打开弹窗预览试题
    },
    // 下载申请表
    downloadApplicationForm(row) {
      this.$post("/biz/apply_template/ct-apply-form-config/applyTemplateDownload", { projectId: row.projectId }, 3000, true, 6)
        .then((res) => {
          if (res.status == "0") {
            for (let item of res.data) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less"></style>
